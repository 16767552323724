import { inject, Injectable } from '@angular/core';
import { map, Observable, of, shareReplay, switchMap } from 'rxjs';

import { IIntegration, IntegrationType } from '@site-mate/sitemate-flowsite-shared';

import { FlowsiteApiHttpClient } from 'app/core/http/clients/api.client';

@Injectable({ providedIn: 'root' })
export class IntegrationService {
  private readonly api = inject(FlowsiteApiHttpClient);

  private cache$: Observable<Map<IntegrationType, IIntegration | undefined>> | null = null;

  /**
   * Query a map of all integrations which uses integration type as the key
   *
   * @returns key-value map of existing integrations
   */
  getIntegrationsMap() {
    if (this.cache$) {
      return this.cache$;
    }

    this.cache$ = of(1).pipe(
      switchMap(() => this.api.get<IIntegration[]>('/integrations')),
      map(this.createIntegrationMap),
      shareReplay(1),
    );
    return this.cache$;
  }

  private createIntegrationMap(integrations: IIntegration[]) {
    return new Map<IntegrationType, IIntegration | undefined>(
      Object.values(IntegrationType).map((integrationType) => [
        integrationType,
        integrations.find((integration) => integration.type === integrationType),
      ]),
    );
  }

  /**
   * Query a specific integration based on the integration type
   *
   * @param integrationType - the integration type to get
   * @returns the integration based on the integration type
   */
  getIntegration(integrationType: IntegrationType) {
    return this.getIntegrationsMap().pipe(
      map((integrationsMap) => {
        const integration = integrationsMap.get(integrationType);
        if (!integration) {
          throw new Error(`No existing integrations found for ${integrationType}`);
        }
        return integration;
      }),
    );
  }
}
